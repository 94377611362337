import React from "react"
import Layout from "../components/layout"
import './style/etat-d-esprit.scss'
import eeImg1 from '../images/etatdesprit/DS-5BAHT-06 3etatdesprit.png';
import eeImg2 from '../images/etatdesprit/DS-5BAHT-06 1etatdesprit.png';
import eeImg3 from '../images/etatdesprit/DS-5BAHT-06 4etatdesprit.png';
const EtatDEsprit = () => {
  return(
    <>
      <Layout className="layout layout-loaded">
        {/*<div className="heroFix heroFix-etatesprit">*/}
        {/*  <div>*/}
        {/*    <span>L8motiv</span>*/}
        {/*    <span>z8geist</span>*/}
        {/*    <span>h8love</span>*/}
        {/*  </div>*/}
        {/*  /!*<picture>*!/*/}
        {/*  /!*  <source srcSet={heroBgWebP} type="image/webp" />*!/*/}
        {/*  /!*  <source srcSet={heroBg} type="image/jpeg" />*!/*/}
        {/*  /!*  <img src={heroBg} alt="background"/>*!/*/}
        {/*  /!*</picture>*!/*/}
        {/*</div>*/}
        <section className="etat-d-esprit">
          <h1
            className="mix-title"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-duration="1000"
            data-sal-easing="ease">L8 <span style={{marginLeft: "-35px"}}>MOTIV</span>
          </h1>
          <div className="section-1">
            <div>
              <img src={eeImg1} alt="L8 MOTIV" />
            </div>
            <div>
              <h3  data-sal="slide-up"
                   data-sal-delay="1000"
                   data-sal-duration="1000"
                   data-sal-easing="ease">Concevoir <br/>Beau, sain et <br/>durable.
              </h3>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Créer un intérieur, c’est exposer une intériorité qui doit refléter, évoquer, suggérer l’essence de ce qu’elle est sans que cela ait besoin d’être expliqué.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Ce que nous souhaitons c’est concevoir beau, sain et durable. Avec ces trois valeurs en guise de socle auxquelles s’ajoutent celles spécifiques au projet, la sensation de l’espace vécu est transformé et agit en nous, car notre humeur est liée à notre environnement, sous toutes ses facettes.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Nous œuvrons à caractériser sans caricaturer, mettre en scène sans muséifier, penser sans figer, pour créer des lieux de vie qui laissent place à l’instinct, à l’instant, et à provoquer cette sorte d’évidence inattendue qu’on attendait d’un espace sans savoir réellement comment le formuler à l’origine.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Orchestrer pour voir se transformer une vision du monde des idées vers le monde tangible, c’est ce qui nous anime, que l’aventure au bout du stylo se réalise en une destination où on aime venir et revenir.
              </p>
            </div>
          </div>
          <h1
            className="mix-title"
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease" style={{textAlign: "right"}}>Z8 <span style={{marginLeft: "-35px"}}>GEIST</span>
          </h1>
          <div className="section-2">
            <div>
              <img src={eeImg2} alt="Z8 GEIST" />
            </div>
            <div>
              <h3  data-sal="slide-up"
                   data-sal-delay="300"
                   data-sal-duration="1000"
                   data-sal-easing="ease">Cycle<br/> d'intuition<br/> D'intention <br/>et d'attention
              </h3>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Il serait osé d’avancer que le résultat est déjà là depuis le début, et c’est pourtant le cas. Il est là, dans les nécessités, les besoins et les envies cachées entres les lignes, car tout commence souvent par des mots.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Une fois questionné le projet se révèle de lui-même, s’y greffent des influences et des inspirations issues de nos expériences passées et actuelles, par extension de ce qui est connu et par intention de ce qui est encore inconnu.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                La conception suit continuellement ce cycle d’intuition, d’intention et d’attention, jusqu’à la fin de la réalisation. C’est le parcours créatif d’une idée qui se précise et se détaille pour exister.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Notre métier c’est de se poser les bonnes questions et de rester connecté au réel pour saisir l’esprit du temps, cet insaisissable zeitgeist. Pour imaginer l’avenir il faut être dans une sorte de veille d’après-garde, qui repère le meilleur du passé et observe les habitudes et attitudes actuelles pour anticiper les tendances longues et émergentes.
              </p>
            </div>

          </div>
          <h1
            className="mix-title"
            data-sal="slide-up"
            data-sal-delay="500"
            data-sal-duration="1000"
            data-sal-easing="ease">H8 <span style={{marginLeft: "-35px"}}>Love</span>
          </h1>
          <div className="section-3">
            <div>
              <img src={eeImg3} alt="H8 Love" />
            </div>
            <div>
              <h3  data-sal="slide-up"
                   data-sal-delay="300"
                   data-sal-duration="1000"
                   data-sal-easing="ease">Tout ce qui <br/>est conçu <br/>doit être <br/>perçu
              </h3>
            </div>
            <div>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Qui est le détenteur du bon goût ? À la fois personne et tout le monde. C’est ce qui constitue l’ambivalence d’un projet.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Tout ce qui est conçu doit être perçu, car peu importe ce qui est pensé au moment de la conception, c’est la perception qui guide les sens sur la qualité du moment vécu.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Notre rôle c’est de maintenir cette vision qui porte le projet, en étant sûr qu’elle sera ressentie comme telle à la fin. Quand on vit une expérience dans un lieu, on ne se pose pas la question de savoir qu'elle expérience nous vivons, mais nous la vivons et nous avons une opinion d’elle après coup.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                L’émotion vient avant la raison, nous savons tous très vite (sans savoir vraiment pourquoi) si nous aimons ou pas une chose.
              </p>
              <p data-sal="slide-up"
                 data-sal-delay="1000"
                 data-sal-duration="1000"
                 data-sal-easing="ease">
                Le projet a une existence propre, avec une personnalité, et nous devons envisager tous ses aspects pour tendre vers des moments de poésie.
              </p>
            </div>
          </div>
        </section>
      </Layout>
    </>
   )
}

export default EtatDEsprit
